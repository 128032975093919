import React, { useEffect } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import Services from '../components/Services';
import * as classes from '../custom-styles/Home.module.css'
import { css } from "@emotion/react"
import ContactPage from '../components/ContactPage';
import { PlayCircleFilled } from '@material-ui/icons'
import TransparentBackground from '../components/backgrounds/TransparentBackground';
import styled from 'styled-components';
import { device } from '../utils/breakpoints';
import { Link } from 'gatsby';
import HomeLogo from '../images/HomeLogo.svg'

const MainContent = styled(TransparentBackground)`
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;

    @media ${device.laptop} {
        width: 100% !important;
        height: 900px !important;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media ${device.tablet} {
        height: 950px !important;
    }

    @media ${device.phone} {
        height: 700px !important;
    }
`;

const HeaderContent = styled.div`
    padding:20px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;

const FreetrialButton = styled.div`
    display:flex;
    align-items:center;
    padding:12px 0;
`;

const SubheaderContent = styled.p`
    color: red;
    font-weight: bold;
    @media ${device.tablet} {
        color: red;
        font-weight: bold;
    }
`;

const RunOn = styled.p`
    color: #052475;
    font-weight: bold;
    @media ${device.tablet} {
        color: #052475;
        font-weight: bold;
    }
`;


export default function HomePage() {

    return (<>
        <MainContent>
            <Container className={`${classes.py50}`}>
                <Row>
                    <Col lg={6}>
                        <HeaderContent>
                            <h1>We orchestrate your business applications wherever you host them</h1>
                            <p>Generative AI . Business Intelligence . Private LLMs . CRMs . Productivity Management . Enterprise Data Management . ERPs</p>
                            <SubheaderContent>Your data must not leave your private network</SubheaderContent>
                            <RunOn>On your Laptop | On-Prem | Any Cloud</RunOn>
                            <FreetrialButton>
                                <Link to={"/console/signup"} className={`${classes.freetrialButton} mx-1`}>Sign Up</Link>
                            </FreetrialButton>
                        </HeaderContent>
                    </Col>
                    <Col className={`${classes.backgroundimagescover}`} lg={6}>
                        <HomeLogo />
                    </Col>
                </Row>
            </Container>
        </MainContent>
        <Services />
        <ContactPage />
    </>)
}
